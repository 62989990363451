import { Image } from "@chakra-ui/react";
import type { ImageProps } from "@chakra-ui/react";

export function PreventionLogo({
  variant,
  ...props
}: {
  variant?: "black" | "color" | "white";
} & ImageProps) {
  let logo = "/images/preventure_logo.svg";

  if (variant === "black") {
    logo = "/images/preventure_logo_black.svg";
  } else if (variant === "white") {
    logo = "/images/preventure_logo_white.svg";
  }

  return (
    <Image src={logo} alt={"Preventure"} maxW={"200px"} {...(props || {})} />
  );
}
